import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent implements OnInit {

  MAINTENANCE_PAGE_HEADER = environment.MAINTENANCE_PAGE_HEADER;
  MAINTENANCE_PAGE_TEXT = environment.MAINTENANCE_PAGE_TEXT;

  constructor() {
  }

  ngOnInit(): void {
  }

}
