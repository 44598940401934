import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppInsightsService {
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string): void { // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({name, average}, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({exception, severityLevel});
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({message}, properties);
  }

}
