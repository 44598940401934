import {BrowserModule} from '@angular/platform-browser';
import {CoreModule} from './core/core.module';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {CountdownModule} from 'ngx-countdown';
import {LangProviderService} from './services/shared/lang-provider.service';
import {AuthGuardService} from './services/auth/auth-guard.service';
import {CookieService} from 'ngx-cookie-service';
import {ErrorHandlerService} from './services/app-insights/error-handler.service';
import {AppInsightsService} from './services/app-insights/app-insights.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    HttpClientModule,
    CountdownModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    LangProviderService,
    AuthGuardService,
    CookieService,
    AppInsightsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(handler: HttpBackend): TranslateHttpLoader {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
