import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppInsightsService} from './services/app-insights/app-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Centura Daily Health Screening';

  constructor(private readonly appInsightsService: AppInsightsService, public translate: TranslateService) {
    translate.addLangs(['en', 'es']);
  }
}
