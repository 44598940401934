import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  setItem(key: string, value: any): void {
    if (value) {
      localStorage.setItem(key, value);
    }
    else {
      localStorage.removeItem(key);
    }
  }

  getItem(key: string): string | null {
    const data: string | null = localStorage.getItem(key);
    if (data !== null) {
      return data;
    }
    return null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }


}
