import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangProviderService {

  constructor() {
  }


  public browserLang = 'en';
  private langSource = new BehaviorSubject(this.browserLang);
  currentBrowserLang = this.langSource.asObservable();


  changeBrowserLang(browserLang: string): void {
    this.langSource.next(browserLang);
  }

  getDefaultBrowserLang(): string {
    return this.browserLang;
  }

}
