
const HOSTNAME = document.location.hostname.toLowerCase();

export interface Environment {
  production: boolean;
  name: string;
  API_BASE_URL: string;
  API_KEY: string;
  OCC_HEALTH_NUMBER: string;
  BADGE_EXPIRATION_IN_HRS: number;
  COOKIE_EXPIRATION_IN_DAYS: number; 
  appInsights: {
    instrumentationKey: string;
  }
  MAINTENANCE_PAGE_HEADER: string;
  MAINTENANCE_PAGE_TEXT: string;
}

export let environment: Environment;
if (HOSTNAME === 'screeningdev.centura.org' || HOSTNAME === 'localhost') {
  environment = {
    production: true,
    name: 'Development',
    API_BASE_URL: 'https://centura-dev.azure-api.net/covid-attest',
    API_KEY: '523def75a88a46b493dede758362b0f9',
    OCC_HEALTH_NUMBER: '303-673-7025',
    BADGE_EXPIRATION_IN_HRS: 20,
    COOKIE_EXPIRATION_IN_DAYS: 30,
    appInsights: {
      instrumentationKey: 'e8cd42ac-6c64-4ecb-9689-1e65f862b2d3',
    },
    MAINTENANCE_PAGE_HEADER: 'This application is no longer necessary and will not be available until further notice.',
    MAINTENANCE_PAGE_TEXT: ' ',
  };
} else if (HOSTNAME === 'screeningtest.centura.org') {
  environment = {
    production: true,
    name: 'Test',
    API_BASE_URL: 'https://centura-test.azure-api.net/covid-attest',
    API_KEY: '81e8ebd6da044df3a4b614cb58f96fe4',
    OCC_HEALTH_NUMBER: '303-673-7025',
    BADGE_EXPIRATION_IN_HRS: 20,
    COOKIE_EXPIRATION_IN_DAYS: 30,
    appInsights: {
      instrumentationKey: '1080d0d5-70e7-4fe2-9313-eb2e716c51cf',
    },
    MAINTENANCE_PAGE_HEADER: 'This application is no longer necessary and will not be available until further notice.',
    MAINTENANCE_PAGE_TEXT: ' ',
  };
} else if (HOSTNAME === 'screening.centura.org') {
  environment = {
    production: true,
    name: 'Production',
    API_BASE_URL: 'https://centura-prod.azure-api.net/covid-attest',
    API_KEY: '9463f247ee234da2bad9075ea7340aab',
    OCC_HEALTH_NUMBER: '303-673-7025',
    BADGE_EXPIRATION_IN_HRS: 20,
    COOKIE_EXPIRATION_IN_DAYS: 30,
    appInsights: {
      instrumentationKey: '25462602-406e-44c8-b300-b76a285fb9a1',
    },
    MAINTENANCE_PAGE_HEADER: 'This application is no longer necessary and will not be available until further notice.',
    MAINTENANCE_PAGE_TEXT: ' ',
  };
}
