import {Injectable} from '@angular/core';
import {LocalStorageService} from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private readonly localStorageService: LocalStorageService) {
  }

  public isAuthenticated(): boolean {
    // The code below is a simplification of this.localStorageService.getItem('token') ? true : false
    return !!this.localStorageService.getItem('token');
  }
}
