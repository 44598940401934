import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceUnavailableComponent } from './service-unavailable/components/service-unavailable/service-unavailable.component';

const routes: Routes = [
  {
    path: '**',
    component: ServiceUnavailableComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], // [RouterModule.forRoot(routes, { enableTracing: true })], 
  exports: [RouterModule]
})
export class AppRoutingModule {
}
